import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import { Link } from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const Certificazione = () => (
  <Layout isPagina={true}>
    <SEO
      title="Certificazione Energetica Lazio, Roma, Aprilia, Pomezia, Latina, Anzio, Nettuno"
      description="Certificazione energetica per tutte le strutture nella regione Lazio. Attestato di Prestazione Energetica APE a Roma, Pomezia, Anzio, Nettuno, Aprilia e Latina"
      location="certificazione-energetica/"
    />
    <BanneronePage title="Certificazione Energetica" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Certificazione Energetica Aprilia, Pomezia, Latina, Anzio, Nettuno, Ardea" />
      <CardPagina variante="bordoLeft bordoRight">
        <h2>APE - Attestato di Prestazione Energetica</h2>
        <Row>
          <Col xs={12}>
            <img
              className="text-center rounded mt-1 mx-auto img-fluid"
              src="../cert/APE2.png"
              alt="Attestato di prestazione energetica"
            />
          </Col>
        </Row>
        <div className="text-center" />
        <p>
          Non più ACE (Attestato di Certificazione Energetica), dal 6 giugno
          2013 per gli edifici di nuova costruzione, quelli sottoposti a
          ristrutturazioni importanti, in caso di vendita o di nuova locazione
          di edifici o unità immobiliari, sarà necessario produrre l'APE ovvero
          <b>l'Attestato di Prestazione Energetica.</b>
        </p>
        <p>
          Con la pubblicazione in Gazzetta del decreto-legge 4 giugno 2013, n.
          63 recante "Disposizioni urgenti per il recepimento della Direttiva
          2010/31/UE del Parlamento europeo e del Consiglio del 19 maggio 2010,
          sulla prestazione energetica nell'edilizia per la definizione delle
          procedure d'infrazione avviate dalla Commissione europea, nonché altre
          disposizioni in materia di coesione sociale" (Gazzetta Ufficiale
          05/06/2013, n. 130), è stato, infatti, sostituito l'art. 6 del decreto
          legislativo 19 agosto 2005, n. 192 che ha previsto la sostituzione
          dell'attestato di certificazione energetica con l'attestato di
          prestazione energetica (APE), rilasciato:
        </p>
        <br />
        <ul>
          <li>
            per gli edifici o le unità immobiliari costruiti, venduti o locati
            ad un nuovo locatario;
          </li>
          <li>
            per gli edifici utilizzati da pubbliche amministrazioni e aperti al
            pubblico con superficie utile totale superiore a 500 m2 (da produrre
            entro centoventi giorni dalla data di entrata in vigore del DL e da
            affiggere presso l'ingresso dell'edificio stesso o in altro luogo
            chiaramente visibile al pubblico) - dal 9 luglio 2015, la soglia di
            500 m2 è abbassata a 250 m2;
          </li>
          <li>per gli edifici scolastici;</li>
          <li>
            per gli edifici di nuova costruzione e quelli sottoposti a
            ristrutturazioni importanti.
          </li>
        </ul>
        <p>Con il nuovo art. 6:</p>
        <ul>
          <b>
            <li>
              è prevista la produzione dell'APE nel caso di vendita o di nuova
              locazione di edifici o unità immobiliari;
            </li>
          </b>
          <li>
            nei contratti di vendita o nei nuovi contratti di locazione di
            edifici o di singole unità immobiliari è inserita apposita clausola
            con la quale l'acquirente o il conduttore danno atto di aver
            ricevuto le informazioni e la documentazione, comprensiva
            dell'attestato, in ordine alla attestazione della prestazione
            energetica degli edifici; nei contratti di vendita o nei nuovi
            contratti di locazione di edifici o di singole unità immobiliari è
            inserita apposita clausola con la quale l'acquirente o il conduttore
            danno atto di aver ricevuto le informazioni e la documentazione,
            comprensiva dell'attestato, in ordine alla attestazione della
            prestazione energetica degli edifici;
          </li>
          <li>
            l'APE può riferirsi a una o più unità immobiliari facenti parte di
            un medesimo edificio. L'attestazione di prestazione energetica
            riferita a più unità immobiliari può essere prodotta solo qualora
            esse abbiano la medesima destinazione d'uso, siano servite, qualora
            presente, dal medesimo impianto termico destinato alla
            climatizzazione invernale e, qualora presente, dal medesimo sistema
            di climatizzazione estiva;
          </li>
          <li>
            <b>
              l'APE ha una validità temporale massima di dieci anni a partire
              dal suo rilascio
            </b>{" "}
            ed è aggiornato a ogni intervento di ristrutturazione o
            riqualificazione che modifichi la classe energetica dell'edificio o
            dell'unità immobiliare. La validità temporale massima è subordinata
            al rispetto delle prescrizioni per le operazioni di controllo di
            efficienza energetica degli impianti termici, comprese le eventuali
            necessità di adeguamento, previste dal decreto del 16 aprile 2013,
            concernente i criteri generali in materia di esercizio, conduzione,
            controllo manutenzione e ispezione degli impianti termici nonché i
            requisiti professionali per assicurare la qualificazione e
            l'indipendenza degli ispettori. Nel caso di mancato rispetto di
            dette disposizioni, l'attestato di prestazione energetica decade il
            31 dicembre dell'anno successivo a quello in cui è prevista la prima
            scadenza non rispettata per le predette operazioni di controllo di
            efficienza energetica. A tali fini, i libretti di impianto sono
            allegati, in originale o in copia, all'attestato di prestazione
            energetica.
          </li>
        </ul>
        <p>
          <b>
            <u>Cos'è la certificazione energetica</u>
          </b>
        </p>
        <p>
          E’ uno strumento nato per misurare quanta energia è necessaria ad un
          appartamento o ad un immobile per assicurare il riscaldamento
          invernale e la climatizzazione estiva ai propri abitanti.
        </p>
        <p>
          In altre parole: quanta energia serve per mantenere le persone in
          condizioni di “comfort” ambientale durante la loro permanenza nei
          locali.
        </p>
        <p>
          Tramite una procedura di analisi, un tecnico abilitato redige un
          documento che riporta, come succede per gli elettrodomestici, in una
          semplice scala composta da otto caselle colorate dal verde (bassi
          consumi, classe “A+”) al rosso (alti consumi, classe “G”), il valore
          di consumo energetico invernale relativo alla casa considerata. <br />{" "}
          In questo modo si può facilmente riconoscere se il proprio edificio
          consuma tanta o poca energia.
        </p>
      </CardPagina>
      <Row>
        <Col xs={12}>
          <img
            className="text-center rounded mt-1 mx-auto img-fluid"
            src="../cert/Attestato-prestazione-energetica-Lazio.jpg"
            alt="Attestato Prestazione Energetica Lazio"
          />
        </Col>
      </Row>

      <CardPagina>
        <h2>
          Attestati di Prestazione Energetica e Pratiche per Detrazioni Fiscali
          (IRPEF 50 e 65%)
        </h2>
        <p>
          L'azienda fornisce assistenza tecnica, predispone la documentazione
          necessaria (Attestato di prestazione energetica, Allegato A, Allegato
          E) e segue interamente l'iter necessario per usufruire della
          detrazione fiscale del 65% in 10 anni, secondo la normativa vigente,
          interfacciandosi direttamente con l'agenzia delle entrate e l'ENEA.
          <br />
          NonsoloEnergia srl provvede alla redazione dell’Attestato di
          Prestazione Energetica (APE), documento senza il quale non è possibile
          usufruire delle detrazioni per gli interventi di riqualificazione
          energetica, quali{" "}
          <u>
            rifacimento totale o parziale dell'involucro esterno degli edifici,
            superfici verticali ed orizzontali, coperture, etc..
          </u>
        </p>
        <p>
          Rivolgendovi ai professionisti dell'azienda, dovrete preoccuparvi solo
          di effettuare i pagamenti con bonifico bancario relativi ai lavori per
          i quali volete chiedere le detrazioni fiscali. Si offre inoltre anche
          assistenza per la scelta degli interventi di risparmio energetico più
          efficaci ed economicamente più convenienti, con relativo piano di
          ammortamento.
        </p>
      </CardPagina>
      <Sezione title="Costo">
        <CardPagina variante="bordoLeft BordoRight">
          <h2>
            Costo diagnosi, pianificazione e certificazione energetica Lazio
            (IVA inclusa)
          </h2>
          <p>
            Nonsoloenergia srl propone alla propria clientela (privati, aziende,
            costruttori, agenzie immobiliari, etc.) tariffe del tutto
            concorrenziali per ogni tipologia di servizio:
          </p>
          <b>Edifici di nuova costruzione</b> (per il permesso a costruire):
          <br />
          <ul>
            <li>
              Pianificazione, progettazione e Cert. Energetica dell’edificio:{" "}
              <strong>€ 10/mq</strong>
            </li>
          </ul>
          <b>Edifici esistenti:</b>
          <ul>
            <li>
              Diagnosi energetica con analisi Termografica: :{" "}
              <strong>€ 5/mq</strong>
            </li>
            <li>
              <u>Rilascio Attestato di Prestazione Energetica</u> (per
              compravendita o locazione):
              <ul>
                <li> - Appartamenti: da euro 200 (forfait)</li>
                <li>- Villini: da euro 300 (forfait)</li>
                <li>
                  {" "}
                  - Edifici industriali: da valutare in seguito a sopralluogo
                  tecnico
                </li>
              </ul>
            </li>
          </ul>
        </CardPagina>
      </Sezione>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              RICHIEDI IL PREVENTIVO PER IL RILASCIO DELL'ATTESTATO DI
              PRESTAZIONE ENERGETICA - APE - PER LA REGIONE LAZIO
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Richiedi il tuo preventivo"
              link="richiesta-informazioni"
            />
          </Col>
        </Row>
      </CardPagina>
      <Row>
        <Col xs={12}>
          <img
            className="text-center rounded mt-1 mx-auto img-fluid"
            src="../cert/Attestato-prestazione-energetica-Anzio-Roma.jpg"
            alt="Attestato Prestazione Energetica Anzio Roma"
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Certificazione
